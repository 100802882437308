<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="12" md="4" class="mt-6">
                {{ title }}
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="mt-6">
                <v-btn small depressed color="primary" @click="dialog = true">
                  {{ btnTitle }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table :headers="headers" :items="modules" :search.sync="search" :loading="loadingPage">
            <template #[`item.created`]="{ item }">
              <div class="text-xs-center">
                {{ item.created | formatDate("MMM DD YYYY") }}
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editModule(item.id)">
                {{ icons.mdiPencil }}
              </v-icon>
              <v-icon small class="mr-2" @click="deleteItem(item.id)">
                {{ icons.mdiDelete }}
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="500">
      <v-card class="pt-3 pb-3">
        <v-card-title class="text-left">
          {{ dialogTitle }}
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-5">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="form.name"
              :prepend-inner-icon="icons.mdiAccountOutline"
              label="Module Name"
              :rules="[v => !!v || 'Name is required']"
              outlined
              dense
              required
            ></v-text-field>
            <v-textarea v-model="form.description" label="Description" outlined dense></v-textarea>

            <v-btn color="primary" @click="save">
              Save
            </v-btn>
            <v-btn outlined class="mx-2" @click="dialog = false">
              Cancel
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" width="500">
      <v-card>
        <v-card-title class="text-left mb-3">
          Delete Module
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-2">
          Are you sure you want to delete this Module?
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="info" text @click="dialogDelete = false">
            Cancel
          </v-btn>
          <v-btn color="error" @click="deleteModule">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPencil, mdiDelete, mdiClose, mdiAccountOutline } from "@mdi/js";
import { mapState } from "vuex";
import module, { moduleCollection } from "@/services/moduleService";

export default {
  name: "Module",
  data: () => ({
    title: "Modules",
    dialogTitle: "Add Module",
    search: null,
    selected: null,
    btnTitle: "Add Module",
    modules: [],
    loadingPage: false,
    valid: true,
    dialog: false,
    dialogDelete: false,
    form: {
      name: null,
      description: null,
      questions: 0,
      created: new Date(),
    },
    icons: {
      mdiPencil,
      mdiDelete,
      mdiClose,
      mdiAccountOutline,
    },
  }),
  computed: {
    ...mapState({
      user: state => state.user.user,
      isLoggedIn: state => state.user.isLoggedIn,
    }),
    headers() {
      const header = [
        { text: "Title", value: "name" },
        { text: "Number of Questions", value: "questions" },
        { text: "Created", value: "created" },
        { text: "Actions", value: "actions", sortable: false },
      ];

      return header;
    },
  },

  created() {
    this.getModules();
  },

  methods: {
    getModules() {
      try {
        moduleCollection.onSnapshot(querySnapshot => {
          const items = [];
          querySnapshot.forEach(doc => {
            const data = doc.data();
            data.id = doc.id;
            items.push(data);
          });
          this.modules = items;
        });
      } catch (error) {
        this.$store.dispatch("notification/add", { type: "error", message: "Data not available" });
      }
    },
    async save() {
      try {
        const newModule = {
          name: this.form.name,
          description: this.form.description,
          questions: this.form.questions,
          created: this.form.created,
        };
        if (this.form.id) await module.updateModule(this.form.id, newModule);
        else {
          await module.addModule(newModule);
        }
        this.dialog = false;
        this.dialogTitle = "Add Module";
        this.clearForm();
        this.$store.dispatch("notification/add", { type: "success", message: "Module saved" });
      } catch (error) {
        this.$store.dispatch("notification/add", { type: "error", message: error.message });
      }
    },
    async editModule(id) {
      await module.getModule(id).then(doc => {
        if (doc.exists) {
          const data = doc.data();
          this.form = data;
          this.form.id = doc.id;
          this.dialogTitle = "Edit Module";
          this.dialog = true;
        } else {
          this.$store.dispatch("notification/add", { type: "error", message: "Data not available" });
        }
      });
    },
    async deleteItem(id) {
      try {
        await module.getModule(id).then(doc => {
          if (doc.exists) this.selected = doc.id;
          this.dialogDelete = true;
        });
      } catch (error) {
        this.$store.dispatch("notification/add", { type: "error", message: `An error occurred : ${error.message}` });
      }
    },
    deleteModule() {
      if (this.selected) {
        try {
          module.deleteModule(this.selected);
          this.dialogDelete = false;
          this.$store.dispatch("notification/add", { type: "success", message: "Module successfully deleted" });
        } catch (error) {
          this.$store.dispatch("notification/add", { type: "error", message: `An error occurred : ${error.message}` });
        }
      }
    },
    clearForm() {
      this.form.name = null;
      this.form.description = null;
      this.form.questions = 0;
      this.form.created = new Date();
      delete this.form.id;
    },
  },
};
</script>
