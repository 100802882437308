/* eslint-disable object-curly-newline */
import { moduleCollection } from "@/utils/firebase";

export default {
  async getModules() {
    const querySnapshot = await moduleCollection.get();

    return querySnapshot;
  },

  async getModule(uid) {
    const docSnap = await moduleCollection.doc(uid).get();

    return docSnap;
  },

  addModule(data) {
    return moduleCollection.doc().set(data);
  },

  updateModule(id, data) {
    return moduleCollection.doc(id).update(data);
  },

  deleteModule(id) {
    return moduleCollection.doc(id).delete();
  },
};

export { moduleCollection };
